<template>
    <div>
        <h1>Hello.</h1>
        <h1 :style="{ fontSize: fontSize + 'px' }" id="face" ref="face">ಠ_ಠ</h1>
    </div>
</template>
  
<script>
export default {
    name: 'HomePage',
    data() {
        return {
            fontSize: 30, // Initial font size
        };
    },
    mounted() {
        this.startAnimation();
        // Listen for the resize event
        window.addEventListener('resize', this.handleResize);
    },
    beforeUnmount() {
        // Remove the event listener when the component is destroyed
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        startAnimation() {
            setInterval(() => {
                // Increase the font size by 1 pixel every second, up to a maximum size
                if (this.fontSize < this.getMaxFontSize()) {
                    this.fontSize += 1;
                }
            }, 100);
        },
        getMaxFontSize() {
            // Calculate the maximum font size based on the screen dimensions
            const screenWidth = window.innerWidth;
            const screenHeight = window.innerHeight;
            const maxSize = Math.min(screenWidth, screenHeight) * 0.3; // Adjust the factor as needed
            return maxSize;
        },
        handleResize() {
            // Reset the font size when the screen is resized
            this.fontSize = 30; // You can set it to the initial size or any other value
        },
    },
};
</script>
  
<style scoped>
#face {
    transition: font-size 1s ease-in-out;
    /* Add a smooth transition effect */
}
</style>
  